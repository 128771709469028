import React, { createContext, useContext, useEffect, useState } from 'react';
import { Collections, WithChildren, Admins, Admin } from 'types';
import { collection, getDocs } from 'firebase/firestore';
import { db } from 'api';

interface AdminsContextValue {
  admins: Admins;
  isUserAdmin: boolean | null;
  setIsUserAdmin(isAdmin: boolean): void;
}

const AdminsContext = createContext({} as AdminsContextValue);
export const useAdminsContext = () => useContext(AdminsContext);

const AdminsContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [admins, setAdmins] = useState<Admins>([]);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchAdmins = async () => {
      const adminsRef = collection(db, Collections.ADMINS);
      const querySnapshot = await getDocs(adminsRef);
      const adminsData = querySnapshot.docs.reduce((acc, doc) => {
        const admin = doc.data() as Admin;

        return [...acc, admin];
      }, [] as Admins);

      setAdmins(adminsData);
    };

    fetchAdmins();
  }, []);

  const value = {
    admins,
    isUserAdmin,
    setIsUserAdmin,
  };

  return <AdminsContext.Provider value={value}>{children}</AdminsContext.Provider>;
};

export default React.memo(AdminsContextProvider);
