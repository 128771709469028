export enum HymnCollections {
  MAIN_HYMNS = 'mainHymns',
  YOUTH_HYMNS = 'youthHymns',
}

export enum Collections {
  MAIN_HYMNS = 'mainHymns',
  USERS = 'users',
  MAIN_HYMNS_CATEGORIES = 'mainHymnsCategories',
  MAIN_HYMNS_SUBCATEGORIES = 'mainHymnsSubcategories',
  YOUTH_HYMNS = 'youthHymns',
  YOUTH_HYMNS_CATEGORIES = 'youthHymnsCategories',
  YOUTH_HYMNS_SUBCATEGORIES = 'youthHymnsSubcategories',
  ADMINS = 'admins',
  APP = 'app',
}
