import { isMobile } from 'react-device-detect';

export const STANDARD_DURATION = isMobile ? 0.4 : 0.7;

// in "rem" unit
export const GLOBAL_FONT_SIZE = 1.5;

export const PADDING_FROM_SCREEN_HORIZONTAL_BORDER = '10px';
export const PADDING_FROM_SCREEN_VERTICAL_BORDER = '10px';

export const DEFAULT_LINE_HEIGHT = isMobile ? 1.1 : 1.3;

export const TELEGRAM_BOT_TOKEN = '7372284378:AAEj8j3Nwco4QA-wKTfdEd3ROSxX33x6o2I';

export const TELEGRAM_BOT_TOKEN_CHAT_ID = 431653422;

export const MELODY_MP3_VERSION = 1;

export const CHORDS_IMG_VERSION = 1;

export const USER_HISTORY_HYMN_TIMEOUT = 3000;

export const MAX_HISTORY_SIZE = 1000;

export const HISTORY_LOADING_SIZE = 30;
export const FAVORITES_LOADING_SIZE = 30;
