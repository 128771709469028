import React, { createContext, useContext, useState } from 'react';
import { WithChildren } from 'types';

export enum AlertTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

interface AlertMessage {
  type: AlertTypes;
  text: string;
}

interface AlertButton {
  text: string;
  onClick(): void;
}

interface AlertContextValue {
  alertMessage?: AlertMessage;
  acceptButton?: AlertButton;
  rejectButton?: AlertButton;
  showAlertMessage: (alertMessage: AlertMessage | undefined) => void;
  setAcceptButton: (alertButton: AlertButton | undefined) => void;
  setRejectButton: (alertButton: AlertButton | undefined) => void;

  removeAlertMessage(): void;
  showLoadingInBackground: boolean;
  setShowLoadingInBackground(show: boolean): void;
  isMessageAlreadyExist(message: string): boolean;
}

const AlertContext = createContext({} as AlertContextValue);
export const useAlertContext = () => useContext(AlertContext);

/*
  TODO: this component is only used to show info about updating the application.
   So, better to leave this component as it is and don't reuse it for other purposes.
   It's good to rename this component and Context Provided dedicated to this component.
*/
const AlertContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [acceptButton, setAcceptButton] = useState<AlertButton | undefined>();
  const [rejectButton, setRejectButton] = useState<AlertButton | undefined>();
  const [showLoadingInBackground, setShowLoadingInBackground] = useState(false);

  const removeAlertMessage = () => {
    setAlertMessage(undefined);
    setAcceptButton(undefined);
    setRejectButton(undefined);
    setShowLoadingInBackground(false);
  };

  const isMessageAlreadyExist = (newMessage: string | undefined) =>
    newMessage === alertMessage?.text;

  const showAlertMessage = (alertMessage: AlertMessage | undefined) => {
    if (!isMessageAlreadyExist(alertMessage?.text)) {
      setAlertMessage(alertMessage);
    }
  };

  const value = {
    alertMessage,
    acceptButton,
    rejectButton,
    showAlertMessage,
    setAcceptButton,
    setRejectButton,
    removeAlertMessage,
    showLoadingInBackground,
    setShowLoadingInBackground,
    isMessageAlreadyExist,
  };

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

export default React.memo(AlertContextProvider);
