export const HYMN_BACKGROUND = '#FEFDE7';
export const BACKGROUND_LIGHT = '#E2E0C6';
export const BACKGROUND_GRAY = '#BDBCA6';
export const TEXT_DARK_BLUE = '#36454F';
export const TEXT_DARK_BLUE_LIGHT = '#7c9eb6';
export const MAIN_HYMN_BACKGROUND = '#36454F';
export const YOUTH_HYMN_BACKGROUND = '#364f3f';
export const YOUTH_HYMN_BACKGROUND_LIGHT = '#87c49e';
export const LIGHT_BLUE_BACKGROUND = 'rgba(54,69,79,0.05)';
export const LOADER_BROWN = '#654321';
export const LOADER_BROWN_LIGHT = '#695745';
export const ORANGE_LIGHT = '#d7b38e';
export const COLOR_WHITE = '#FFF';
export const INPUT_BORDER = '#C0C0C0';
export const INPUT_BORDER_HOVER = '#212121';
export const COLOR_BLACK = '#000';
export const LIGHT_BLUE = '#AADCF6';
export const DARK_BLUE = '#1876D1';
export const LIGHT_RED = '#FF7070';
export const DARK_RED = '#dc2a2a';
export const DARK_RED_TRANSPARENT = 'rgba(220,42,42,0.8)';
export const DEFAULT_BUTTON = '#4EA8DC';
export const LINK = '#3537da';
export const DEFAULT_MUI_SUCCESS = '#2e7d32';
