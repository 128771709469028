import { createTheme } from '@mui/material/styles';
import { HYMN_BACKGROUND, TEXT_DARK_BLUE } from 'constants/colors';
import { GLOBAL_FONT_SIZE } from 'constants/common';

const theme = createTheme({
  typography: {
    fontFamily:
      'GentiumBookPlus-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
          color: TEXT_DARK_BLUE,
          margin: 0,
          height: '100vh',
          width: '100vw',
          fontSize: `${GLOBAL_FONT_SIZE}rem`,
          backgroundColor: HYMN_BACKGROUND,
          fontFamily:
            '"GentiumBookPlus-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
      },
    },
  },
});

export default theme;
