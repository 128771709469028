import React, { createContext, useContext, useState } from 'react';
import { WithChildren } from 'types';

export enum NotificationTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

interface NotificationMessage {
  type: NotificationTypes;
  text: string;
  time?: number;
}

interface NotificationContextValue {
  notificationMessage?: NotificationMessage;
  setNotificationMessage: (notificationMessage: NotificationMessage | undefined) => void;
  removeNotificationMessage(): void;
}

const NotificationContext = createContext({} as NotificationContextValue);
export const useNotificationContext = () => useContext(NotificationContext);

const NotificationContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage | undefined>();

  const removeNotificationMessage = () => {
    setNotificationMessage(undefined);
  };

  const value = {
    notificationMessage,
    setNotificationMessage,
    removeNotificationMessage,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default React.memo(NotificationContextProvider);
