export enum YouthHymnsAvailableLanguages {
  // cases should be appropriate with textUa, textRu, etc.
  UA = 'Ua',
  RU = 'Ru',
}

export interface YouthHymnParagraphLineAccord {
  id: string;
  chord: string;
  // it's an index of the letter in text
  position: number;
}

export interface YouthHymnParagraphLine {
  id: string;
  textUa?: string;
  textRu?: string;
  accordsUa?: YouthHymnParagraphLineAccord[];
  accordsRu?: YouthHymnParagraphLineAccord[];
}

// just need to count all boolean fields in the Paragraph type. The case is important.
// it's needed for typing of toggleParagraphFieldValue() function
export enum YouthHymnParagraphBooleanFields {
  HAS_LEFT_MARGIN = 'hasLeftMargin',
  IS_MAIN_CHORUS = 'isMainChorus',
  IS_REPEATED = 'isRepeated',
}

export interface YouthHymnParagraph {
  id: string;
  topNoteUa?: string;
  topNoteRu?: string;
  // like "repeat twice"
  bottomNoteUa?: string;
  bottomNoteRu?: string;
  // like "1", "2", "7b" (in case with a bible verse)
  leftNoteUa?: string;
  leftNoteRu?: string;
  // for choruses for example
  hasLeftMargin?: boolean;
  // the field is needed to not duplicate lines if there will be repeated choruses
  isMainChorus?: boolean;
  isRepeated?: boolean;
  // in case when isRepeated === true this field isn't present
  lines?: YouthHymnParagraphLine[];
}

export interface YouthHymn {
  docId: string;
  number: number;
  capoHint?: string;
  paragraphs?: YouthHymnParagraph[];
  bottomNote?: string;
  topNote?: string;
  extraInfo?: string;
}

export interface YouthHymnsUserParams {
  showAccords?: boolean;
  preferredLanguage?: YouthHymnsAvailableLanguages;
}

export type YouthHymns = YouthHymn[];
