import React, { createContext, useContext, useEffect, useState } from 'react';
import { Workbox as WorkboxInstance } from 'workbox-window/Workbox';
import { LocalStorageItems, WithChildren } from 'types';

interface WorkboxContextValue {
  updateAvailable: boolean;
  updateRejected: boolean;
  isServiceWorkerInstalling: boolean;
  isServiceWorkerReady: boolean;
  setUpdateRejected(isRejected: boolean): void;
  setUpdateAvailable(isAvailable: boolean): void;
  isWBInstalling(): Promise<boolean>;
  workbox: WorkboxInstance | null;
}

const WorkboxContext = createContext({} as WorkboxContextValue);
export const useWorkboxContext = () => useContext(WorkboxContext);

const WorkboxContextProvider: React.FC<WithChildren> = ({ children }) => {
  const [workbox, setWorkbox] = useState<WorkboxInstance | null>(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateRejected, setUpdateRejected] = useState(false);
  const [isServiceWorkerReady, setServiceWorkerReady] = useState(false);
  const [isServiceWorkerInstalling, setIsServiceWorkerInstalling] = useState(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      import(/* webpackChunkName: "workbox-window" */ 'workbox-window').then(({ Workbox }) => {
        const wb = new Workbox('/service-worker.js');
        wb.addEventListener('waiting', () => {
          console.log('wb: waiting');
          setServiceWorkerReady(false);
          /*
            TODO: here we used to setUpdateAvailable(true) to show users a prompt notification on the top
             about a new release. Maybe current implementation will cause problems, so for now we leave this comment.
             We didn't use wb.messageSkipWaiting(); before.
           */
          wb.messageSkipWaiting();
        });
        wb.addEventListener('controlling', () => {
          console.log('wb: controlling');
          setServiceWorkerReady(false);
          window.location.reload();
        });
        wb.addEventListener('installing', () => {
          console.log('wb: installing');
          setIsServiceWorkerInstalling(true);
          setServiceWorkerReady(false);
        });
        wb.addEventListener('activated', () => {
          console.log('wb: activated');
          setServiceWorkerReady(true);
        });
        wb.register();
        setWorkbox(wb);
        console.log('wb: registered');
      });
    }
  }, []);

  useEffect(() => {
    const checkValidServiceWorker = () => {
      window.localStorage.setItem(
        LocalStorageItems.IS_SERVICE_WORKER_DISABLED,
        JSON.stringify(false),
      );

      // Check if the service worker can be found. If it can't reload the page.
      fetch(`${window.location.origin}/service-worker.js`, {
        headers: { 'Service-Worker': 'script' },
      })
        .then(response => {
          // Ensure service worker exists, and that we really are getting a JS file.
          const contentType = response.headers.get('content-type');
          if (
            response.status === 404 ||
            (contentType != null && contentType.indexOf('javascript') === -1)
          ) {
            // No service worker found. Probably a different app. Reload the page.
            console.log('Service worker is disabled or invalid');
            window.localStorage.setItem(
              LocalStorageItems.IS_SERVICE_WORKER_DISABLED,
              JSON.stringify(true),
            );
          } else {
            window.localStorage.setItem(
              LocalStorageItems.IS_SERVICE_WORKER_DISABLED,
              JSON.stringify(false),
            );
          }
        })
        .catch(() => {
          console.log('No internet connection found. App is running in offline mode.');
        });
    };

    if (workbox) {
      checkValidServiceWorker();
    }
  }, [workbox]);

  useEffect(() => {
    const timer = window.setInterval(async () => {
      if (workbox) {
        await workbox.update();
        console.log('workbox: update()');
        setUpdateRejected(false);
      }
      // 30 min
    }, 30 * 60 * 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, [workbox]);

  const isWBInstalling = async () => {
    const isServiceWorkerDisabled = JSON.parse(
      localStorage.getItem(LocalStorageItems.IS_SERVICE_WORKER_DISABLED) || JSON.stringify(false),
    );

    if (!isServiceWorkerDisabled) {
      const sw = await workbox?.getSW();

      // log service worker states
      console.log('sw state:', sw?.state);
      if (sw?.state === 'installing' || sw?.state === 'activating') {
        return true;
      }
    }
    return false;
  };

  const value = {
    updateAvailable,
    updateRejected,
    setUpdateRejected,
    workbox,
    setUpdateAvailable,
    isServiceWorkerReady,
    isServiceWorkerInstalling,
    isWBInstalling,
  };

  return <WorkboxContext.Provider value={value}>{children}</WorkboxContext.Provider>;
};

export default React.memo(WorkboxContextProvider);
