import React, { ComponentType, ReactElement, ReactNode, Suspense } from 'react';

type PropsWithFallback<T> = T & {
  fallback?: NonNullable<ReactNode> | null;
};

// TODO: Component typing is hardcoded a bit. Need to use LazyExoticComponent here for typing.
export default function withFallback<T>(
  Component: ComponentType<PropsWithFallback<T>>,
): ComponentType<PropsWithFallback<T>> {
  return (props: PropsWithFallback<T>): ReactElement => {
    const { fallback = null } = props;
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
}
