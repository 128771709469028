import React, { createContext, ReactNode, useContext, useState } from 'react';
import { WithChildren } from 'types';

interface GlobalResponsiveDialogData {
  headerText: string;
  disagreeButtonText: string;
  agreeButtonText: string;
  agreeButtonHandler(): void;
  contentText?: string | ReactNode;
  disagreeButtonHandler?(): void;
}

interface GlobalResponsiveDialogValue {
  removeGlobalResponsiveDialog(): void;
  globalResponsiveDialogData: GlobalResponsiveDialogData | null;
  open: boolean;
  showGlobalResponsiveDialogData(data: GlobalResponsiveDialogData): void;
  setOpen(open: boolean): void;
}

const GlobalResponsiveDialog = createContext({} as GlobalResponsiveDialogValue);
export const useGlobalResponsiveDialog = () => useContext(GlobalResponsiveDialog);

const GlobalResponsiveDialogProvider: React.FC<WithChildren> = ({ children }) => {
  const [globalResponsiveDialogData, setGlobalResponsiveDialogData] =
    useState<GlobalResponsiveDialogData | null>(null);
  const [open, setOpen] = React.useState(false);

  const removeGlobalResponsiveDialog = () => {
    setGlobalResponsiveDialogData(null);
  };

  const showGlobalResponsiveDialogData = (data: GlobalResponsiveDialogData) => {
    setGlobalResponsiveDialogData(data);
    setOpen(true);
  };

  const value = {
    removeGlobalResponsiveDialog,
    showGlobalResponsiveDialogData,
    globalResponsiveDialogData,
    open,
    setOpen,
  };

  return (
    <GlobalResponsiveDialog.Provider value={value}>{children}</GlobalResponsiveDialog.Provider>
  );
};

export default React.memo(GlobalResponsiveDialogProvider);
